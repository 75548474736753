<template>
<div class="community-create">
   <div class="notice vertical-center">
  <img src="~assets/images/index/notice.png" class="notice-img"/>
  <span>发布须知：悬赏求助为问答求助专区，发错板块将被隐藏！</span>
  </div>
  <van-field v-model="title" maxlength='50'  placeholder="请输入悬赏标题" border="{{ false }}"  class="title" />
  <van-divider class="line" />
  <van-field v-model="content" rows="5"  maxlength='2000' show-word-limit placeholder="请输入正文内容..." type="textarea" border="{{ false }}" class="content" />
  <van-uploader v-model="fileList"  :max-count="5" :after-read="afterRead" :before-delete="deleteImg"  />
  <van-cell title="悬赏分类" is-link :value="category_value" @click="changeCategory()" title-class="cell-l" />
  <van-divider class="line" />
  <van-cell title="悬赏积分" is-link :value="credit_value"  @click="changeIntegral()" title-class="cell-l"/>
  <van-divider class="line" />
  <van-cell title="回答可见" is-link :value="answer_value"  @click="changeAnswer()" title-class="cell-l"/>
  <van-divider class="line" />
  <div class="btmbox"></div>
  <van-action-sheet v-model:show="categoryShow" :actions="category" cancel-text="取消" description="选择悬赏分类" close-on-click-action @cancel="onClose"
  @select="categorySelect" />
  <van-action-sheet v-model:show="integralShow" :actions="creditList" cancel-text="取消" description="选择悬赏积分" close-on-click-action @cancel="onClose"
  @select="integralSelect" />
  <van-action-sheet v-model:show="answerShow" :actions="answerList" cancel-text="取消" description="选择回答权限" close-on-click-action @cancel="onClose"
  @select="answerSelect" />
  <div class="foot">
    <div class="footer vertical-center">
    <div class="footer-l vertical-center" @click="sub('0')" >
      <img class="footer-l-img" src="~assets/images/index/draft.png" />
      存草稿
    </div>
    <div class="footer-r vertical-center" @click="sub('1')" >
      发布
    </div>
    
  </div>
  <div class="safety-height"></div>
  </div>
  <van-toast id="van-toast" />
</div>
</template>

<script>
import UserInfo from "components/userInfo";
import _ from 'lodash'
export default {
  name: "community.create",
  data(){
    return{
        id:'',
        fileList: [],
        categoryShow: false,
        integralShow:false,
        answerShow:false,
        title:'',
        content:'',
        category:[],
        category_id:'',
        category_value:'请选择分类',
        credit:'',
        credit_value:'请选择积分',
        answer_visible:'1',
        answer_value:'所有人可见',
        fileUrl:[],
        osscdn: "",
        subbtn:true,
        creditList:[
        
        ],
        answerList:[
          {
            value:0,
            name:'仅自己和回答人可见'
          },
          {
            value:1,
            name:'所有人可见'
          },
        ],
    }
  },

    created() {
      if (this.$route.query.id) {
        this.id=this.$route.query.id
        this.info()
      }
      this.CommunityCategory()
      this.configs()
          
  },
  methods:{
     configs(){
       let url = this.$api.configs + '?key=config_bounty'
      this.$http.get(url, false).then(res => {
        if(res.data.success){
          let arr =[]
          for(let i in res.data.data.value.bounties){
            let obj = {
              name: res.data.data.value.bounties[i]
            }
          arr.push(obj)
          }
          this.creditList = arr
        }
      })
  },
    //详情
    info(){
      let url = this.$api.CommunityBounty +'/'  + this.$api.edit   + '/' + this.id 
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          let list = []
            for ( let i of res.data.data.picture){
              let item = {}
              item.type = 'image',
              item.url = i
              list.push(item)
            }
                this.category_id=res.data.data.category_id
              this.credit=res.data.data.credit
              this.answer_visible=res.data.data.answer_visible
              this.title=res.data.data.title
              this.content=res.data.data.content
              this.status=res.data.data.status
              this.fileUrl=res.data.data.picture
              this.credit_value=res.data.data.credit
              this.fileList=list
              this.category_value=res.data.data.community_categories.name
            if(res.data.data.answer_visible == 0){
              this.answer_value='仅自己和回答人可见'
            }
        }
      })
    },
    //提交
    sub: _.debounce(function(status){
      if(!this.subbtn){
        return
      }
      if(this.category_id == ''){
        this.$toast('请选择悬赏分类');
        return
      }
      if(this.title == ''){
        this.$toast('请输入悬赏标题');
        return
      }
      if(this.title.length < 4){
        this.$toast('标题为4-50字');
        return
      }

      if (status == 1){

        if(this.credit == '' ){
          this.$toast('请选择悬赏积分');
          return
        }
        if(this.credit == '' && this.credit != 0){
          this.$toast('请选择悬赏积分');
          return
        }
        if(this.content == ''){
          this.$toast('请输入正文');
          return
        }

      }
      let url = this.$api.CommunityBounty 
      if(this.id){
        if( this.status == 1 &&  status == 0){
          this.$toast('已发布不可再存草稿');
          return
        }
        let data = {
          category_id:this.category_id,
          credit:this.credit,
          answer_visible:this.answer_visible,
          title:this.title,
          content:this.content,
          status:status,
          picture:this.fileUrl,
          id:this.id
        }
        this.$http.put(url,data, true).then(res => {
          if(res.data.success){
            this.subbtn = false
            this.$toast('修改成功');
              this.$store.commit('M_ACT','0')
               if(status == 0){
            this.$store.commit('M_ACTS','1')
          }else{
            this.$store.commit('M_ACTS','0')
          }
              this.$router.go(-1)
          }else{
            this.$toast(res.data.message);
          }
        })
      }else{
        let data = {
          category_id:this.category_id,
          credit:this.credit,
          answer_visible:this.answer_visible,
          title:this.title,
          content:this.content,
          status:status,
          picture:this.fileUrl
        }
        this.$http.post(url,data, true).then(res => {
          if(res.data.success){
               this.subbtn = false
                 if(status == 0){
            this.$toast('保存成功');
          }else{
            this.$toast('发布成功');
          }
            
            this.$store.commit('M_ACT','0')
            this.$router.go(-1)
          }else{
            this.$toast(res.data.message);
          }
        })
      }
      
    },500),
    //社区分类
    CommunityCategory(){
      let url = this.$api.CommunityCategory 
      this.$http.get(url, true).then(res => {
        if(res.data.success){
            this.category=res.data.data
        }
      })
    },
    afterRead(event) {
       let data =new FormData()
      data.append('file', event.file)
      data.append('type', 'community')
      this.$http.post(this.$api.imageUpload, data, true).then(res => {
          if(res.data.success){
              this.fileUrl= this.fileUrl.concat(res.data.data.url)
          } else {
            //   wx.showToast({
            //       title: '上传失败',
            //       icon: 'error',
            //       duration: 2000
            //   })
          }
      })
      },
    //      //  删除图片
    deleteImg(event) {
        let list = this.fileList
        let url = this.fileUrl
        list.splice(event.index,1)
        url.splice(event.index,1)
        this.fileList=list
          this.fileUrl=url
    },
    //标题
    // titleChange(event){
    //   this.setData({
    //     title:event.detail
    //   })
    // },
    //内容
    // contentChange(event){
    //   this.setData({
    //     content:event.detail
    //   })
    // },
    //分类
    changeCategory(){
        this.categoryShow=true
    },
    //积分
    changeIntegral(){
      this.integralShow=true
    },
    //可见
    changeAnswer(){
      this.answerShow=true
    },
    onClose() {
        this.categoryShow=false
          this.integralShow=false 
          this.answerShow=false
    },
    
      categorySelect(event) {
        this.category_id=event.id,
          this.category_value=event.name
      },
      integralSelect(event) {
        this.credit=event.name,
          this.credit_value=event.name
      },
      answerSelect(event) {
        this.answer_visible=event.value
          this.answer_value=event.name
      },
 
  },
  components:{
    UserInfo,
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/create.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell:after{
  border: unset;
}
.cell-l{
  width: 80px;
  flex: none;
}
.van-cell__value{
  text-align: left;
}
   .van-uploader {
      margin: 0 50/$r;
    }
  
    .van-uploader__preview {
      width: 200/$r;
      height: 200/$r;
    }
  
    .van-uploader__preview-image, .van-uploader__upload {
      width: 200/$r!important;
      height: 200/$r!important;
    }
  
</style>